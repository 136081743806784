import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import TextImage from "../components/textImage"
import ImageColumn from "../components/imageColumn"

const Ansichtssache = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "ansichtssache-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index2: file(relativePath: { eq: "ansichtssache-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "ansichtssache-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index4: file(relativePath: { eq: "ansichtssache-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index5: file(relativePath: { eq: "ansichtssache-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index6: file(relativePath: { eq: "ansichtssache-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index7: file(relativePath: { eq: "ansichtssache-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Ansichtssache"/>
      <HeroImage source={data.index1.childImageSharp.fluid}/>
      <TextImage headline={"Ansichtssache"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Pack die Natur in die Backstube – Wenn wir unseren Blick mal auf die Natur richten, dann liegt ihre
              Besonderheit und Schönheit vor allem in ihrer Einfachheit. Diese Tatsache und die Eindrücke, die ich auf
              meiner Road to Bakery sammeln durfte, haben mich zu dem Entschluss gebracht, Brot in seiner einfachsten
              Form herzustellen und zu backen.
            </p>
          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Wir können mit unbehandelten Rohstoffen und unserem Know How, welches wir uns über Jahre angeeignet haben,
              einzigartige Brote herstellen, wenn wir den Fokus richtig setzen und vor allem nicht verlieren. So, wie
              die Erde es nun schon seit tausenden von Jahren mit Ihren „Produkten“ macht, denn auch diese brauchen oft
              viel Zeit, bis sie ausgereift und in ihrer Perfektion vollendet sind. Die einzige Unterstützung ist hier
              die Kraft der Natur.
            </p>
          </div>
        </div>
      </TextImage>
      <ImageColumn source_left={data.index2.childImageSharp.fluid}
                   source_right={data.index3.childImageSharp.fluid}/>
      <TextImage source_position={"top"}
                 source={data.index4.childImageSharp.fluid}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Ob Bäume, Wiesen oder Berge… letzten Endes setzt das Panorama alles in Szene. Unser Panorama, der Laden
              ist ebenso geprägt von meiner #roadtobakery. Viele Dinge, die mir die verschiedensten Orte dieser Erde
              gezeigt haben, sind hier mit eingeflossen und haben ihn zu einem neuen, besonderen Ort gemacht. Jeder hat
              seine eigene Philosophie, und meine ist es, die Natur auf das Backen so gut wie möglich zu adaptieren.
            </p>
          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Und wenn wir uns anschauen, wie lange die Natur braucht, bis gewisse Dinge wirklich gut sind, sollten wir
              es als selbstverständlich betrachten, auch unseren Broten ihre Zeit zu geben, die sie eben brauchen. So,
              wie die Erde, die es uns vormacht…
              <br/>Diese Idee vom Backhandwerk leben nicht nur wir – auf der #roadtobakery
              konnte Max viele Eindrücke aus verschiedenen Bachstuben sammeln.
            </p>
            <br/>
            <br/>
            <div data-srgrid-row="m-center">
              <div data-srgrid-col="xs-18">
                <Link to={"/road-to-bakery"} className={"_ta-left h2 _td-none _d-block"}>
                  Erfahre mehr über
                  Max Road to Bakery
                </Link>
              </div>
            </div>
          </div>
        </div>
      </TextImage>
      <ImageColumn source_left={data.index5.childImageSharp.fluid}
                   source_right={data.index6.childImageSharp.fluid}/>
      <HeroImage source={data.index7.childImageSharp.fluid}/>
    </Layout>
  )
}


export default Ansichtssache
